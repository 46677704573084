import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import { getListingsById } from '../../../ducks/marketplaceData.duck';
import { FormattedMessage } from '../../../util/reactIntl';
import SearchResultsPanel from '../../SearchPage/SearchResultsPanel/SearchResultsPanel';

import css from './SectionSearchResults.module.css';

function SectionSearchResultsComponent(props) {
  const { listings }      = props;
  const { pageId }        = useParams();
  const pagination        = useSelector(state => state.hostedAssets?.searchResultsData?.[pageId]?.meta);
  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const totalItems        =
          hasPaginationInfo
            ? pagination.totalItems
            : pagination?.paginationUnsupported
              ? listings.length
              : 0;
  return <div className={css.searchListingsPanel}>
    <div className={css.searchResultSummary}>
      <FormattedMessage
        id="MainPanelHeader.foundResults"
        values={{ count: totalItems }}
      />
    </div>
    <SearchResultsPanel
      listings={listings}
      pagination={null}
      isMapVariant={false}
    />
  </div>;
}

function mapStateToProps(state) {
  const { searchResultsData, currentPageAssets } = state.hostedAssets || {};
  const currentPageResultIds = currentPageAssets.flatMap(pageID => searchResultsData?.[pageID]?.data ?? []).map(l => l.id);
  const listings = getListingsById(state, currentPageResultIds);
  return { listings };
}

const SectionSearchResults = compose(
  connect(mapStateToProps)
)(SectionSearchResultsComponent);

export default SectionSearchResults;
